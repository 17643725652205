import React from "react"

import { Container, Row, Col } from 'reactstrap'
import Link from '../components/link'
import Button from '../components/btn'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from '../components/form'
import Slider from '../components/slider'
import Box from '../components/box'
import Hr from '../components/hr'
import { FaHome, FaDesktop, FaMobileAlt, FaChartLine, FaBong, FaChalkboardTeacher } from 'react-icons/fa';
import {IoIosArrowDroprightCircle} from 'react-icons/io'
import { GoDashboard } from 'react-icons/go';
import Clients from '../components/clients'
import styled from 'styled-components'
import PageTitle from '../components/page-title'
import Img from 'gatsby-image/withIEPolyfill'
import { FaCode } from 'react-icons/fa'
import { StaticQuery, graphql } from "gatsby"

let StyledImg = styled(props => <Img {...props}/>)`
  perspective: 1500px;
  perspective-origin: left center;
  overflow: visible !important;
  picture, img {
    transform: rotateY(-35deg) rotateX(15deg);
    box-shadow: 25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2);
    border-radius: .625rem;
    transition: 1s !important;
    &:hover {
      transform: rotateY(-30deg) rotateX(15deg);
    }
  }
`

let StyledBackground = styled.div`
  background: linear-gradient(to bottom,#f9fbfd 0,#fff 100%);
`

const Background = styled.div`
  background: #943cff;
  color: white;
  padding: 5rem 0;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
  text-align: center;
`

let Service = ({title, Icon = FaHome, link, details}) => (
  <div class="col-md-4">
    <Link to={link}>
      <Box>
        <Icon size={40}/>
        <h4 className="mt-3">{title}</h4>
        <p>{details}</p>
      </Box>
    </Link>
  </div>
)

let Tool = ({title, icon, content}) => (
  <div className="d-flex mb-5" align-items="flex-start">
    <img src={icon} width="75px"/>
    <div className="ml-3">
      <h4>{title}</h4>
      <p className="m-0 text-muted">{content}</p>
    </div>
  </div>
)

export default () => (
  <StaticQuery
    query={graphql`
      query query {
        file(relativePath: {eq: "dashboard_sample.png"}) { 
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <SEO title="Dashboard | Empathy | Data agency" description="Empathy help business make better digital presence with data. We help you consolidate, process and visualize your data to help you monitor your performance &amp; find insights to improve your online performance." keywords="data visualization, dashboard, interactive dashboard, Tableau, Google Data Studio, Klipfolio, Excel, Google Spreadsheets, Google Analytics"/>
        <Background>
          <h2>"The purpose of visualization is insight, not pictures."</h2>
          <p>Ben Shneiderman - pioneer of human–computer interaction</p>
        </Background>
        <Container className="pt-4">
          <div className="text-center">
            <h1>Interactive Dashboard</h1>
            <p className="text-muted">Create useful data visualization that drives insights.</p>
          </div>
        </Container>
        <Container className="py-4">
          <Row className="d-flex align-items-center">
            <Col md="6">
              <div className="mb-4">
                <h2 className="text-primary">Our Approach</h2>
                <p>Successful data visualization requires deep understanding of business questions and how different users solve them from different angles.</p>
                <div className="d-flex mb-4">
                  <div><IoIosArrowDroprightCircle size={30} className="text-primary"/></div>
                  <div className="ml-3">
                    <h4>Requirement gathering </h4>
                    <p className="m-0 text-muted">We conduct workshops, deliver questionaire, review and audit your data to understand your needs and discover any data gaps to be filled.</p>
                  </div>
                </div>
                <div className="d-flex mb-4">
                  <div><IoIosArrowDroprightCircle size={30} className="text-primary"/></div>
                  <div className="ml-3">
                    <h4>Dashboard development</h4>
                    <p className="m-0 text-muted">We work with your users, IT and data teams to ensure the dashboard solution fulfills both end users and IT requirements.</p>
                  </div>
                </div>
                <div className="d-flex mb-4">
                  <div><IoIosArrowDroprightCircle size={30} className="text-primary"/></div>
                  <div className="ml-3">
                    <h4>Dashboard training</h4>
                    <p className="m-0 text-muted">We provide training to illustrate how the dashboard can help drive insights from different users' perspective.</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="6">
              <StyledImg fluid={data.file.childImageSharp.fluid} objectFit="contain" objectPosition="50% 50%"/>
            </Col>
          </Row>
        </Container>   
        <Container className="py-4">
          <h2 className="text-primary mb-2">Platforms We Use</h2>
          <p class="mb-5">We use visualization platforms that suits your needs &amp; IT requirements.</p>
          <Row>
              <Col>
                <Tool title="Google Data Studio" icon="/images/logo/googledatastudio.svg" content="Google's dashboard platform, tightly integrated with other Google's advertising products."/>  
                <Tool title="Tableau" icon="/images/logo/tableau.png" content="Powerful, paid dashboard platform that integrates to large number of data sources, including marketing platforms &amp; databases."/>  
              </Col>
              <Col>
                <Tool title="Klipfolio" icon="/images/logo/klipfolio.png" content="Web-based dashboard platform which supports integrations to variety of marketing platforms."/>  
                <Tool title="Excel / Google Sheets" icon="/images/logo/excel.png" content="Most widely used data analysis software, support different plugins to integrate 3rd party data."/>  
              </Col>
          </Row>
        </Container>
        <StyledBackground>
          <div className="py-5">
            <Container>
              <Row className="d-flex justify-content-center">
                <Col md={8}>
                  <Box style={{textAlign: 'left'}}>
                    <h3 className="text-center">Contact Us</h3>
                    <Hr/>
                    <Form/>
                  </Box>
                </Col>
              </Row>
            </Container>
          </div>
        </StyledBackground>
      </Layout>
    )}
  />
)
